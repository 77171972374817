import React from "react"
import { navigate } from "gatsby"
import WrappedNormalLoginForm from "./login-form"
import { connect } from "react-redux"

class Login extends React.Component {
  render() {
    console.log("isLoggedIn = ", this.props.isLoggedIn)
    if (this.props.isLoggedIn) {
      navigate(`/app/profile`)
    }

    return (
      <>
        <h1>Log in</h1>
        <WrappedNormalLoginForm />
      </>
    )
  }
}

export default connect(
  ({ loading, user, isLoggedIn }) => ({
    loading,
    user,
    isLoggedIn,
  }),
  dispatch => ({})
)(Login)
