import { clientOnly } from "./isClient"
import { getAccessToken, getProfile, logout } from "../../services/auth"

const getSeparator = (url: string) => (url.indexOf("?") >= 0 ? "&" : "?")

export const redirectBackWithToken = clientOnly(async () => {
  const token = getAccessToken()

  const query = new URLSearchParams(window.location.search)
  const returnUrl = query.get("r")

  if (token && returnUrl) {
    try {
      await getProfile()

      window.location.href = returnUrl + getSeparator(returnUrl) + "t=" + token
    } catch (ex) {
      console.error(ex)

      if (ex.status === 401) {
        logout()
      }
    }
  }
})
