import React, { FormEvent } from "react"
import { Button, Checkbox, Form, Icon, Input, message } from "antd"
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { handleLogin } from "../services/auth"
import { navigate } from "gatsby"
import { redirectBackWithToken } from "./common/redirectBackWithToken"

type NormalLoginFormProps = {
  form: any
}

class NormalLoginForm extends React.Component<NormalLoginFormProps> {
  handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    this.props.form.validateFields(
      async (
        err: any,
        values: { username: string; password: string; remember: boolean }
      ) => {
        if (!err) {
          await handleLogin(values)
          console.log("login succeeded, redirecting...")
          redirectBackWithToken()
          console.log("redirected, navigating to `/app/profile`...")
          await navigate(`/app/profile`)
          console.log("navigated")
        } else {
          message.error(JSON.stringify(err))
        }
      }
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your password!" }],
          })(
            <Input
              type="password"
              placeholder="Password"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true,
          })(<Checkbox>Remember me</Checkbox>)}
          <a className="login-form-forgot" href="">
            Forgot password
          </a>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
          Or <a href="">register now!</a>
        </Form.Item>
      </Form>
    )
  }
}

const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(
  NormalLoginForm
)

export default WrappedNormalLoginForm
